@import url(https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
/*@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');*/

:root {
    /*--primary-color: rgb(84, 156, 206);*/
    /*--primary-color-light: rgb(0, 156, 163);*/

    /*--secundary-color: rgb(127, 143, 159);*/
    /*--secundary-color-light: rgba(127, 143, 159, .6);*/

    /*--body-color: rgb(255, 255, 255);*/
    /*--body-color-dark: rgb(248, 249, 250);*/

    /*--color-dark: rgb(72, 84, 96);*/
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}


html {
    box-sizing: border-box;
    font-size: 62.5%;
}

body {
    /*background-color: var(--body-color);*/
    /*font-family: 'Nunito', sans-serif;*/
    font-family: 'Aleo', serif;
    overflow-x: hidden;
}

i {
    font-size: inherit;
}

input, textarea {
    outline: none;
}

button, input[type="submit"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

li {
    list-style-type: none;
}

